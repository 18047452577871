import { useQuery } from '@tanstack/vue-query'
import currenciesData from '../fixtures/currencies'

export function useCurrencies() {
    const { execute } = useApi('/api/currencies', 'GET')
    const {
        execute: executeGetExchangeRates,
        loading: loadingGetExchangeRate,
    } = useApi('/api/exchange-rate/{iso}', 'GET')

    const getExchangeRates = (iso) => {
        return executeGetExchangeRates({
            params: {
                iso,
            },
        }).then((response) => {
            return response
        })
    }

    const getCurrencies = () => {
        return execute().then((response) => {
            return response.data.map((item) => ({
                value: item.id,
                label: item.symbol,
                iso: item.iso,
                is_default: item.is_default,
                rate: item.rate,
                rate_updated_at: item.rate_updated_at,
                symbol: item.symbol,
                decimal_places: item.decimal_places,
                format: item.format,
            }))
        })
    }

    const { data, isLoading } = useQuery({
        queryKey: ['currencies'],
        queryFn: () => getCurrencies(),
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
    })

    const currencies = computed(() => data.value ?? [])
    const defaultCurrency = computed(() =>
        currencies.value.find((currency) => currency.is_default)
    )

    return {
        currencies,
        defaultCurrency,
        isLoading,
        currenciesData,
        getCurrencies,
        getExchangeRates,
        loadingGetExchangeRate,
    }
}
